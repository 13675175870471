import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Lightbox from "../lightbox";

class P5Showcase extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log("component mounted");
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
                    query {
                        allFile(
                            filter: {
                                extension: { regex: "/(jpg)/" }
                                relativeDirectory: {
                                    eq: "portfolio/P5 Outdoor"
                                }
                            }
                            sort: { fields: base, order: ASC }
                        ) {
                            nodes {
                                base
                                childImageSharp {
                                    fluid(quality: 80) {
                                        ...GatsbyImageSharpFluid
                                        originalImg
                                    }
                                }
                            }
                        }
                    }
                `}
        render={data => <Lightbox productImages={data.allFile.nodes} />}
      />
    );
  }
}

export default P5Showcase;
