import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import OrderButton from "../../../components/orderbutton";
import ProductTable from "../../../components/productTable";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import ProductImage from "../../../images/product/outdoor/P5/Product-Summary-P5-Outdoor.jpg";
import Content from "../../../content/produk/outdoor/P5/Product-Page.json"
import P5Showcase from "../../../components/product/outdoor/p5Showcase";

const p5 = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
              title={Content.seo.title}
              description={Content.seo.description}
              lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1 style={headerStyle}>
                            P5 Outdoor LED Display Module
                        </h1>
                        <Row>
                            <Col sm={true}>
                                <Image src={ProductImage} fluid />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2>Product Summary</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    P.5 Outdoor merupakan layar LED penuh warna
                                    dengan kepadatan pixel tinggi yang
                                    dipergunakan di luar ruangan (outdoor)
                                    sehingga yang cocok digunakan pada public
                                    area, periklanan, reklame digital, sistem
                                    informasi pemerintahan, sistem informasi
                                    transportasi publik (bandara, stasiun MRT,
                                    pelabuhan, jalan raya, jalan tol), stage
                                    Outdoor dan Sangat cocok dipergunakan
                                    sebagai media promosi Sebuah Usaha atau
                                    Lembaga dan berbagai keperluan lainnya. P.5
                                    Outdoor memiliki kualitas sebagai berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>Ringan dan warna dapat disesuaikan.</li>
                                    <li>
                                        Kepadatan pixel yang tinggi di luar
                                        ruangan sehingga menghasilkan visual
                                        yang detail dan menarik.
                                    </li>
                                    <li>
                                        Media yang inovatif, interaktif,
                                        informatif untuk berbagai keperluan.
                                    </li>
                                    <li>Struktur yang kuat dan tahan lama.</li>
                                </ul>
                                <p>
                                    <OrderButton messageText={Content.messageText} />
                                </p>
                            </Col>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Unit Module
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"Unit Module"}
                                    tableData={Content.moduleUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.cabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P5 Outdoor
                                    </h2>
                                </div>
                                <P5Showcase />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};
p5.propTypes = {
    siteTitle: PropTypes.string,
};

p5.defaultProps = {
    siteTitle: ``,
};
export default p5;
